<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="申请人身份：">
        <el-select v-model="searchForm.identity_type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="服务商" :value="1"></el-option>
          <el-option label="商家" :value="2"></el-option>
          <el-option label="发展商" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请单号：">
        <el-input size="small" v-model="searchForm.withdraw_no" placeholder="请输入申请单号"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="提现状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已结算" :value="2"></el-option>
          <el-option label="已驳回" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否关闭：">
        <el-select v-model="searchForm.admin_status" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="是" :value="-1"></el-option>
          <el-option label="否" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button size="small" @click="exportEx">导出</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total-num">
      <div>筛选后总提现金额：{{ withdrawal_amount }}</div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="withdraw_no" label="申请单号" align="center"></el-table-column>
      <el-table-column prop="facilitator_info.name" label="所属运营商姓名" align="center"></el-table-column>
      <el-table-column prop="facilitator_info.mobile" label="所属运营商电话" align="center"></el-table-column>
      <el-table-column prop="identity_type" label="申请人身份" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? '服务商' : row.identity_type == 2 ? '商家' : '发展商' }}
        </template>
      </el-table-column>
      <el-table-column prop="identity_type" label="申请人姓名" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? row.f_name : row.identity_type == 2 ? row.s_name : row.r_name }}
        </template>
      </el-table-column>
      <el-table-column prop="identity_type" label="申请人联系电话" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? row.f_mobile : row.identity_type == 2 ? row.s_mobile : row.r_mobile }}
        </template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" label="提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column prop="status" label="提现状态" align="center">
        <template v-slot="{ row }">{{ row.status == 1 ? '待审核' : row.status == 2 ? '已结算' : '已驳回' }}</template>
      </el-table-column>
      <el-table-column label="提现类型" align="center">
        <template v-slot="{ row }">{{ row.withdraw_type == 1 ? '银行卡' : row.withdraw_type == 2 ? '微信零钱' : '' }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template v-slot="{ row }">
          <el-button @click="audit(row)" type="text" v-if="row.status == 1">打款</el-button>
          <el-button @click="seeDetail(row)" type="text">查看详情</el-button>
          <el-button @click="seeBalance(row)" type="text">收入明细</el-button>
          <el-button @click="toChangeSmall(row)" type="text" v-if="row.withdraw_type == 2 && !row.batch_id">打款到零钱</el-button>
          <el-button @click="close(row)" type="text">{{ row.admin_status == -1 ? '不关闭' : '关闭' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="showAudit" width="650px">
      <el-form label-width="100px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="2">同意打款</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开户行：">{{ currentInfo.bank_info }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_card }}</el-form-item>
        <el-form-item label="持卡人：">{{ currentInfo.bank_name }}</el-form-item>
        <el-form-item label="打款金额：" v-if="auditForm.status == 2">
          <el-input v-model="auditForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item label="打款凭证：" v-if="auditForm.status == 2">
          <ReadyUploadSource
            :showStyle="{
              width: '150px',
              height: '100px',
            }"
            @getSource="val => (auditForm.voucher = val.path)"
            :path="auditForm.voucher"
            @removeThis="() => (auditForm.voucher = '')"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="auditForm.status == -1">
          <el-input type="textarea" :rows="5" v-model="auditForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAudit = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="showDetail" width="650px">
      <el-form label-width="100px">
        <el-form-item label="审核状态：">{{ currentInfo.status == 1 ? '待审核' : currentInfo.status == 2 ? '已结算' : '已驳回' }}</el-form-item>
        <el-form-item label="开户行：">{{ currentInfo.bank_info }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_card }}</el-form-item>
        <el-form-item label="持卡人：">{{ currentInfo.bank_name }}</el-form-item>
        <el-form-item label="打款金额" v-if="currentInfo.status == 2">{{ currentInfo.remit_amount }}</el-form-item>
        <el-form-item label="打款凭证" v-if="currentInfo.status == 2">
          <el-image style="width: 160; height: 90px" :src="currentInfo.voucher" :preview-src-list="[currentInfo.voucher]"></el-image>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="currentInfo.status == -1">{{ currentInfo.remark }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看明细" :visible.sync="showBalance" width="780px">
      <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="生成时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收益支出" align="center">
          <template v-slot="{ row }">
            {{ row.type == 1 ? 'wifi' : row.type == 2 ? '第三方活动' : row.type == 3 ? '扫码点餐' : '提现' }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="预估收益" align="center">
          <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import config from '@/util/config';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        start_time: '',
        end_time: '',
        identity_type: '',
        withdraw_no: '',
        mobile: '',
        status: '',
        admin_status: 0,
      },
      list: [],
      total_number: 0,
      showAudit: !1,
      currentInfo: {},
      withdrawal_amount: '',
      auditForm: {
        status: 2,
        remark: '',
        remit_amount: '',
        voucher: '',
      },
      showDetail: !1,
      showBalance: !1,
      balanceList: [],
      balance_total: 0,
      balance_page: 1,
      balance_rows: 10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    balanceUpdateData(val, status) {
      if (status == 0) {
        this.balance_rows = val;
      } else {
        this.balance_page = val;
      }
      this.getBalanceList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        start_time: '',
        end_time: '',
        identity_type: '',
        withdraw_no: '',
        mobile: '',
        status: '',
        admin_status: 0,
      };
      this.getList();
    },
    // 导出数据成excel
    // exportEx() {
    //   let searchForm = _.cloneDeep(this.searchForm);
    //   delete searchForm.page;
    //   delete searchForm.rows;
    //   if (searchForm.start_time) {
    //     searchForm.end_time = searchForm.start_time[1] / 1000;
    //     searchForm.start_time = searchForm.start_time[0] / 1000;
    //   }
    //   this.$axios.post(this.$api.serviceProvider.exportWithdraw, searchForm).then(res => {
    //     if (res.code == 0) {
    //       let path = config.baseurl + '/' + res.result;
    //       let a = document.createElement('a');
    //       a.href = path;
    //       a.id = 'download';
    //       document.body.appendChild(a);
    //       a.click();
    //       let aDom = document.getElementById('download');
    //       document.body.removeChild(aDom);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
     // 导出
    exportEx() {
      let searchForm = _.cloneDeep(this.searchForm);
      delete searchForm.page;
      delete searchForm.rows;
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios
        .post(this.$api.serviceProvider.exportWithdraw, searchForm)
        .then(res => {
          if (res.code == 0) {
            this.open();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
     open() {
        this.$alert('导出中，请耐心等待，稍后跳转到导出文件中下载', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      },
    audit(row) {
      this.currentInfo = row;
      this.showAudit = !0;
      this.auditForm = {
        id: row.id,
        status: 2,
        remark: '',
        remit_amount: '',
        voucher: '',
      };
    },
    seeDetail(row) {
      this.currentInfo = row;
      this.showDetail = !0;
    },
    seeBalance(row) {
      this.balance_page = 1;
      this.balance_rows = 10;
      this.balanceList = [];
      this.currentInfo = row;
      this.showBalance = !0;
      this.getBalanceList();
    },
    close(row) {
      this.$axios
        .post(this.$api.serviceProvider.setAdminStatus, {
          id: row.id,
          admin_status: row.admin_status == 1 ? -1 : 1,
        })
        .then(res => {
          if (res.code == 0) {
            row.admin_status = row.admin_status == 1 ? -1 : 1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getBalanceList() {
      let data = {
        page: this.balance_page,
        rows: this.balance_rows,
        identity_type: this.currentInfo.identity_type,
      };
      if (data.identity_type == 1) data.facilitator_id = this.currentInfo.facilitator_id;
      if (data.identity_type == 2) data.store_id = this.currentInfo.store_id;
      if (data.identity_type == 3) data.regimental_id = this.currentInfo.regimental_id;
      this.$axios.post(this.$api.serviceProvider.BalanceLog, data).then(res => {
        if (res.code == 0) {
          this.showBalance = !0;
          this.balanceList = res.result.list;
          this.balance_total = res.result.total_number;
          console.log(res);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.withdrawList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
          this.withdrawal_amount = res.result.withdrawal_amount;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comfirmAudit() {
      let auditForm = this.auditForm;
      if (auditForm.status == 2) {
        if (!auditForm.remit_amount) return this.$message.warning('请填写打款金额');
        if (!auditForm.voucher) return this.$message.warning('请添加打款凭证');
      } else {
        if (!auditForm.remark) return this.$message.warning('请填写驳回原因');
      }
      this.$axios.post(this.$api.serviceProvider.withdrawAudit, auditForm).then(res => {
        if (res.code == 0) {
          this.showAudit = !1;
          this.$message.success('处理成功');
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 打款到零钱
    toChangeSmall(data) {
      this.$confirm('此操作将打款到微信零钱, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.serviceProvider.toMoney, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('打款成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;
    div {
      margin-right: 40px;
    }
  }
}
</style>